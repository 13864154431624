import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Grid, Icon, Modal,Popup } from "semantic-ui-react";
import "./BuildingHeatmap.css";
import { AppContext } from "../../../Context/AppContext";
import MDL_UNIT_HISTORY from "./Components/mdl_unit_history/mdl_unit_history";
import MDL_PROGRESS from "../../../components/cmp-progress/cmp-progress";
import {
  downloadHeatmapAPI,
  getBuildingHeatmapAPI,
  getSingleBuilding,
  getAllPests
} from "../../../constants";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";

export default function BuildingHeatmap() {
  /* VARIABLE DECLARATIONS
    ---------------------------------------------------------------------------------*/
  const { building_id } = useParams();
  const history = useHistory();
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const { buildingUnits, selectedBuilding, bInfo,sbuildingData } = useContext(AppContext);
  const [building_units, setBuildingUnits] = buildingUnits;
  const [single_building_data,setSingleBuildingData] = sbuildingData;
  const [, setBuildingInfo] = bInfo;
  const [selected_building, setSelectedBuilding] = selectedBuilding;
  const [building_heatmap, setBuildingHeatmap] = useState({});
  const [var_mdl_unit_history, set_mdl_unit_history] = useState(false);
  const [var_first_loading,set_first_loading]=useState(true);
  const [filter, setFilter] = useState([
    {
      value: 3,
      text: "3 Months",
    },
    {
      value: 6,
      text: "6 Months",
    },
    {
      value: 9,
      text: "9 Months",
    },
    {
      value: 12,
      text: "12 Months",
    },
  ]);
  const [selected_unit, set_selected_unit] = useState(null);
  const [selected_filter, setSelectedFilter] = useState(3);
  const [legends, setLegends] = useState({});
  const [pestTreated, setPestTreated] = useState();
  const [pest_value,set_pest_value]=useState('all');
  const [pestUpdated,setpestUpdated]=useState(false);

 /* EVENT LISTENERS
    ---------------------------------------------------------------------------------*/
  // useEffect(() => {
  //   if (selected_unit) {
  //     openModalUnitHistory();
  //   } else {
  //     closeModalUnitHistory();
  //   }
  // }, [selected_unit]);

  useEffect(() => {
    if (building_heatmap.length) getLegendValues();
  }, [building_heatmap]);


  useEffect(() => {
    if (selected_filter) getBuildingHeatmap();
  }, [selected_filter]);

  useEffect(() => {
    // Check both conditions in a single effect
    if (selected_building && var_first_loading) {
      getBuildingHeatmap();
      getPests();
    }
  }, [var_first_loading, selected_building]);

  useEffect(()=>{
    if(pestUpdated){
      getBuildingHeatmap();
    }
  },[pestUpdated])

  useEffect(() => {
    if (single_building_data.length === 0) {
      getBuildingUnits();
    }
  }, [single_building_data]);


/* ASYNC FUNCTIONS
    ---------------------------------------------------------------------------------*/
  function getIntegerFromString(val) {
    // console.log("Data::::regex", val);
    var re = /^(-?\d*)[^0-9]*(\d*)\.([\s\S]*?)$/;
    return parseInt(val?.replace(re, "$1$2"), 10);
  }

  function isAlphanumericWithSpace(inputString) {
    // Use a regular expression to check if the string is alphanumeric with space
    return /^[0-9]+$/.test(inputString) && /^[a-zA-Z]+$/.test(inputString);
  }

  async function getBuildingHeatmap() {
    if(selected_building){
      set_mdl_progress(true);
      let API_URL = `${getBuildingHeatmapAPI}${
        localStorage.getItem("s_id") || selected_building
      }`;
      // let API_URL = "https://homepro-test.ps19testserver.com/api/buildings/heat-map/08f5e439-32db-4abb-aa9e-2778042d7dcb";
      if (selected_filter) {
        API_URL += `?month=${selected_filter}`;
      }
  
      if(pest_value !== "all"){
        API_URL += `&pestID=${pest_value}`;
      }
  
      await fetch(API_URL)
        .then((response) => response.json())
        .then((response) => {
          if (response.data) {
            setBuildingHeatmap(response.data);
          } else {
            setBuildingHeatmap({});
          }
          set_mdl_progress(false);
            setpestUpdated(false);
        });
    }else{
      setSelectedBuilding(building_id);
    }
    
  }

    
  function handleModalUnitHistory(unit){
    set_selected_unit(unit);
    if (unit) {
      openModalUnitHistory();
    } else {
      closeModalUnitHistory();
    }
  }
  function openModalUnitHistory() {
    set_mdl_unit_history(true);
  }

  function closeModalUnitHistory() {
    set_mdl_unit_history(false);
  }

  function onChangeFilter(event, { value }) {
    if (value) setSelectedFilter(value);
  }

  function getActivityLevel(unit) {
    let className = "";
    if (unit) {
      if (unit.activity_level === "Low activity") {
        className = "light-activity";
      } else if (unit.activity_level === "Moderate activity") {
        className = "moderate-activity";
      } else if (unit.activity_level === "High activity") {
        className = "heavy-activity";
      } else if (unit.activity_level === "No activity") {
        className = "none-activity";
      } else if (
        !unit.activity_level &&
        !unit.prep_level &&
        !unit.sanitation_level &&
        !unit.last_invoice_id
      ) {
        className = "not-serviced";
      }
    } else {
      className = "";
    }
    return className;
  }

  function getLegendValues() {
    var no_activity = 0;
    var light_activity = 0;
    var medium_activity = 0;
    var high_activity = 0;
    var refused_entry = 0;
    var prepared = 0;
    var not_prepared = 0;
    var average_sanitation = 0;
    var low_sanitation = 0;
    var high_sanitation = 0;
    var structural_issues = 0;

    building_heatmap.forEach((data) => {
      no_activity += data.no_activity_count;
      light_activity += data.low_activity_count;
      medium_activity += data.medium_activity_count;
      high_activity += data.high_activity_count;
      refused_entry += data.refused_entry_count;
      prepared += data.prepared_count;
      not_prepared += data.not_prepared_count;
      average_sanitation += data.average_sanitation_count;
      low_sanitation += data.low_sanitation_count;
      high_sanitation += data.high_sanitation_count;
      structural_issues += data.high_sanitation_count;
    });

    setLegends({
      no_activity,
      light_activity,
      medium_activity,
      high_activity,
      refused_entry,
      average_sanitation,
      low_sanitation,
      high_sanitation,
      prepared,
      not_prepared,
    });
  }

  function getIconName(filtered_value) {
    let icon = "";
    if (filtered_value.refused_entry) {
      icon = <Icon color="red" name="minus circle"></Icon>;
    } else if (
      filtered_value.not_prepare ||
      filtered_value.prep_level === "Not Prepared"
    ) {
      icon = <Icon color="orange" name="close" key="close" />;
    } else if (filtered_value.sanitation) {
      icon = <Icon name="paint brush"></Icon>;
    }
    return icon;
  }

  function getInstance() {
    if (!building_heatmap || !building_heatmap.floors) {
      return <></>;
    }
    const floors = Object.keys(building_heatmap.floors);
    return floors.map((floor, i) => {
      var floorsChildren = building_heatmap.floors[floor];
      if (floorsChildren.length > 0) {
        return (
          <div
            className="flex row-wrapper"
            style={{ alignItems: "center", cursor: "pointer" }}
            key={i}
          >
            <span className="text-center first-column-spacing text-center">
              {floor}
            </span>
            {(() => {
              const jsxArray = [];
              let index = 0;
              var previousNumber = null;
              var tempVals = [];
              for (var k = 0; k < floorsChildren.length; k++) {
                if (isAlphanumericWithSpace(floorsChildren[k]?.unit)) {
                  tempVals.push(floorsChildren[k]);
                } else {
                  var currentNumber = getIntegerFromString(
                    floorsChildren[k]?.unit
                  );
                  if (previousNumber !== null && currentNumber !== null) {
                    while (++previousNumber < currentNumber) {
                      tempVals.push(null);
                    }
                  }
                  tempVals.push(floorsChildren[k]);
                  previousNumber = currentNumber;
                }
              }
              floorsChildren = tempVals;
              do {
                const child = floorsChildren[index];
                if (child) {
                jsxArray.push(
                  <div
                    className={`column-border ${
                      i === 0 ? "borderTop" : ""
                    } ${getActivityLevel(child)}`}
                    key={index}
                  >
                    {(
                      <div
                        className="flex flex-column flex-center icon-container-size"
                        style={{ cursor: "pointer" }}
                      >
                        {child?.upcoming_orders && child?.upcoming_orders?.length > 0 &&
                        <Popup
                          trigger={<span class="note"></span>}
                        ><div style={{display:'flex',flexDirection:'column'}}>
                            <p><b>Upcoming</b></p>
                          {child?.upcoming_orders?.map((i)=>{
                            return (<>
                            <span>{i.unit_number} - {i.service_code}</span>
                            </>)
                          })}
                          </div>
                        </Popup>
                      }
                        <span className="text-center text-wrap">
                        {child && (
                            <>
                            {/* {child.lastOrderStatus === "in progress" ? (
                              <Icon color="grey" name="bell" />
                            ) : null}
                      
                            {child.lastOrderStatus === "scheduled" ? (
                              <Icon color="grey" name="bell" />
                            ) : null} */}
                      
                            {child.structural_issues ? (
                              <span><Icon name="wrench"/></span>
                            ) : null}
                      
                            {child.refused_entry ? <Icon color={child?.activity_level === "High activity" ?"black":"red"} name="minus circle" /> : null}
                      
                            {(child.not_prepare || child.prep_level === "Not Prepared") ? (
                              <Icon color={child?.activity_level === "High activity" ?"black":"orange"} name="close" key="close" />
                            ) : null}
                      
                            {child.sanitation ? (
                              <Icon name="paint brush" />
                            ) : null}
                          </>
                          )}
                        </span>
                        <span className="text-center text-wrap">
                        <a onClick={(e) => {
                            e.preventDefault();
                            e.target.style.color = '#681DA8'
                              handleModalUnitHistory(child.unit);
                            }} style={{color: '#4C4C4C'}}>{child?.unit}
                          </a>
                        </span>
                      </div>
                    )}
                  </div>
                );
              }
                index++;
              } while (index < floorsChildren.length);

              return jsxArray;
            })()}
          </div>
        );
      }

      return null;
    });
  }

  async function downloadReport() {
    var pestFilter = "";
    if(pest_value !== "all"){
      pestFilter = `&pestID=${pest_value}`;
    }
    const url = `${
      downloadHeatmapAPI + building_id
    }?month=${selected_filter}${pestFilter}&download=true`;
    // console.log("Url", url);
    try {
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  }
  const OnChangePest = (e, { value }) => {
    set_pest_value(value);
    setpestUpdated(true);
  }

  async function getPests() {
    await fetch(getAllPests)
      .then((response) => response.json())
      .then((res) => {
        let allTempPest = [];
        if (res.code === 200) {
          allTempPest = res.data.map((value) => ({
            key: value.pestName,
            text: value.pestName,
            value: value.pestId,
          }));
  
          // Adding the "All" object at the beginning of the array
          allTempPest.unshift({
            key: 'all',
            text: 'All',
            value: 'all',
          });
  
          setPestTreated(allTempPest);
        }
      });
  }

  async function getBuildingUnits() {
    await fetch(
      `${getSingleBuilding}${building_id}`
    )
      .then((response) => response.json())
      .then((response) => {
        const final_data = response.data[0];
        setSingleBuildingData(final_data);
      });
  }

/* RENDER APP
    ---------------------------------------------------------------------------------*/
  return (
    <div className="app-content">
      <div className="app-backto-prev-page">
        <div
          className="app-goback-button"
          onClick={() => {
            history.goBack();
          }}
        >
          <Icon name="arrow left" /> Go Back
        </div>
      </div>
      <div className="app-card">
          <div className="app-card-header">
            <div className="app-card-title">Building Heatmap</div>
            <div className="app-content-action-right">
              <Form.Select
                className="sortby_optionss"
                id="sortby_optionss"
                name="sortby_optionss"
                placeholder="Select Filter"
                style={{marginRight:'8px'}}
                label="Filter: "
                onChange={onChangeFilter}
                value={selected_filter}
                options={filter}
              />
                <Form.Select
                search
                className="sortby_optionss"
                name="pest_id"
                id="pest_id"
                style={{marginRight:'8px'}}
                width={3}
                label="Pest: "
                placeholder="Select pest"
                selectOnBlur={false}
                value={pest_value || 'all'}
                options={pestTreated}
                onChange={OnChangePest}
                required
              />
              {/* Button to download heat */}
              <Button
                color="green"
                type="button"
                content="Download"
                onClick={() => {
                  downloadReport();
                }}
                icon="download"
              ></Button>
            </div>
          </div>
          <Grid>
            <Grid.Column computer={4} tablet={16} className="flex flex-column">
              <span className="flex">
                <span className="building-name-title">Address:</span>
                <span>{single_building_data?.building_address}</span>
              </span>

              <span className="flex">
                <span className="building-name-title">Date:</span>
                <span>{moment().format("LL")}</span>
              </span>
            </Grid.Column>
            <Grid.Column
              style={{ margin: "" }}
              computer={12}
              tablet={16}
              className=""
            >
              <table class="mobile-table-row">
                <thead>
                  <tr class="table-border mobile-table">
                    {/* <td class="table-data">Legend</td> */}
                    <td class="table-data text-bold text-center">
                      Not Serviced
                    </td>
                    <td class="table-data text-bold text-center">
                      Heavy Activity
                    </td>
                    <td class="table-data text-bold text-center">
                      Light Activity
                    </td>
                    <td class="table-data text-bold text-center">
                      Moderate Activity
                    </td>
                    <td class="table-data text-bold text-center">
                      No Activity
                    </td>
                    <td class="table-data text-bold text-center">
                      Not Prepared<Icon color="red" name="close"></Icon>
                    </td>

                    <td class="table-data text-bold text-center">
                      Refused Entry{" "}
                      <Icon color="red" name="minus circle"></Icon>
                    </td>
                    <td class="table-data text-bold text-center">
                      Sanitation <Icon name="paint brush"></Icon>
                    </td>
                    <td class="table-data text-bold text-center">
                      Structural <Icon name="wrench"/>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {building_heatmap?.legend && (
                    <tr class="table-border mobile-table">
                      {/* <td class="table-data">%</td> */}
                      <td class="not-serviced table-data text-center">
                        {building_heatmap.legend["Not_Serviced"]?.count}
                      </td>
                      <td class="heavy-activity table-data text-center">
                        {building_heatmap.legend["Heavy_Activity"]?.count}
                      </td>
                      <td class="light-activity table-data text-center">
                        {building_heatmap.legend["Light_Activity"]?.count}
                      </td>
                      <td class="moderate-activity table-data text-center">
                        {building_heatmap.legend["Moderate_Activity"]?.count}
                      </td>
                      <td class="table-data text-center">
                        {building_heatmap.legend["None_Activity"]?.count}
                      </td>
                      <td class="table-data text-center">
                        {building_heatmap.legend["Not_Prepared"]?.count}
                      </td>

                      <td class="table-data text-center">
                        {building_heatmap.legend["Refused_Entry"]?.count}
                      </td>
                      <td class="table-data text-center">
                        {building_heatmap.legend["Sanitation"]?.count}
                      </td>
                      <td class="table-data text-center">
                        {building_heatmap.legend["Structural_Issues"]?.count}
                      </td>
                    </tr>
                  )}
                  {building_heatmap?.legend && (
                    <tr class="table-border mobile-table">
                      {/* <td class="table-data">%</td> */}
                      <td class="not-serviced table-data text-center">
                        {building_heatmap.legend["Not_Serviced"]?.percentage}%
                      </td>
                      <td class="heavy-activity table-data text-center">
                        {building_heatmap.legend["Heavy_Activity"]?.percentage}%
                      </td>
                      <td class="light-activity table-data text-center">
                        {building_heatmap.legend["Light_Activity"]?.percentage}%
                      </td>
                      <td class="moderate-activity table-data text-center">
                        {
                          building_heatmap.legend["Moderate_Activity"]
                            ?.percentage
                        }
                        %
                      </td>
                      <td class="table-data text-center">
                        {building_heatmap.legend["None_Activity"]?.percentage}%
                      </td>
                      <td class="table-data text-center">
                        {building_heatmap.legend["Not_Prepared"]?.percentage}%
                      </td>

                      <td class="table-data text-center">
                        {building_heatmap.legend["Refused_Entry"]?.percentage}%
                      </td>
                      <td class="table-data text-center">
                        {building_heatmap.legend["Sanitation"]?.percentage}%
                      </td>
                      <td class="table-data text-center">
                        {
                          building_heatmap.legend["Structural_Issues"]
                            ?.percentage
                        }
                        %
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Grid.Column>
          </Grid>
          <div className="heatmap-container">
            <div className="" style={{ margin: "0" }}>
              {getInstance()}
            </div>
          </div>
        </div>

      {/***** MODAL: REPORT_UNITS **************************************************************************/}
      <Modal
        id="mdl-unit-history"
        dimmer={"inverted"}
        size="large"
        open={var_mdl_unit_history}
        onClose={() => {
          set_mdl_unit_history(false)
          set_selected_unit(false)}}
      >
        <MDL_UNIT_HISTORY
          unit_number={selected_unit}
          filter={selected_filter}
          pestValue={pest_value}
          set_mdl_open={set_mdl_unit_history}
          building_id={building_id}
          set_selected_unit={set_selected_unit}
        />
      </Modal>

      {/***** MODAL: PROGRESS **************************************************************************/}

      {/***** MODAL: PROGRESS **************************************************************************/}

      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress}
          var_modaltitle="Please wait"
          var_message={"Please wait..."}
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}
    </div>
  );
}
