import React, { useState, useEffect, useContext } from "react";
import { Icon, Button, Form, Input,Dropdown, Modal } from "semantic-ui-react";
import { updateUser,schedulerLocationLookup } from "../../../../constants";
import { useToasts } from "react-toast-notifications";
import "./mdl-update-scheduler.css";
import { AppContext } from "../../../../Context/AppContext";
import MDL_PROGRESS from "../../../../components/cmp-progress/cmp-progress";

export default function MDL_UPDATE_CUSTOMER({ set_mdl_open, scheduler }) {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/

  const { userInfo, validateTextField,schedulerCheckOrderlist } = useContext(AppContext);
  const [user, setUser] = userInfo;
  const [var_update_scheduler, set_update_scheduler] = useState(scheduler);
  const [schedulers, setSchedulers] = useState();
  const [schedulersArr, setschedulersArr] = useState([]);
  const [var_disabled, set_disabled] = useState(false);
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const [schedulerOrderlist, set_schedulerOrderlist] = schedulerCheckOrderlist;
  const { addToast } = useToasts();
  const [errors] = useState([]);
  let filteredArray;

  /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
   // Fetch scheduler data
   useEffect(() => {
    async function getSchedulerData() {
      try {
        set_mdl_progress(true);
        const response = await fetch(schedulerLocationLookup);
        const res = await response.json();
        const formattedSchedulers = res.data.map(scheduler => ({
          value: scheduler.value,
          text: `${scheduler.text} (${scheduler.email})`
        }));
        setschedulersArr(res.data);
        setSchedulers(formattedSchedulers);
      } catch (error) {
        console.error("Error fetching schedulers:", error);
      }
      set_mdl_progress(false);
    }
    getSchedulerData();
  }, []);

  // Set initial values when scheduler changes
  useEffect(() => {
    if (scheduler && schedulersArr.length > 0) {
      const associatedEmails = scheduler.associated_emails ? scheduler.associated_emails.split(",") : [];
      
      const associatedData = associatedEmails.map(email => 
        schedulersArr.find(p => p.email.trim() === email.trim())
      ).filter(Boolean); // Remove null values if any emails don't match

      const formattedSchedulers = associatedData.map(scheduler => ({
        value: scheduler.value,
        text: `${scheduler.text} (${scheduler.email})`
      }));

      set_update_scheduler(prevState => ({
        ...prevState,
        first_name:scheduler?.first_name,
        last_name:scheduler?.last_name,
        email: scheduler.email,
        password:scheduler?.password,
        filteredArray: formattedSchedulers
      }));
    }
  }, [scheduler, schedulersArr]);

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
  const onChangeInput = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (name === "email") {
      var pattern_email = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern_email.test(value)) {
        errors["email"] = "Please enter valid email address.";
      } else {
        errors["email"] = null;
      }
    } else if (name === "new_password") {
      if (!value || value?.length === 0) {
        errors["new_password"] = "Please write a valid new password.";
      } else if (value.length < 8) {
        errors["new_password"] = "Password length must be 8 or more.";
      } else {
        errors["new_password"] = null;
      }
    } else if (name === "first_name") {
      if (validateTextField(value) === false) {
        errors["first_name"] = "Please enter valid first name.";
      } else {
        errors["first_name"] = null;
      }
    }
     else if (name === "last_name") {
      if(value){
        if (validateTextField(value) === false) {
          errors["last_name"] = "Please enter valid last name.";
        } else {
          errors["last_name"] = null;
        }
      }else{
        errors["last_name"] = null;
      }
    }
    if(errors[name] === null) {
      set_disabled(false);
    }else{
      set_disabled(true);
    }
    set_update_scheduler({ ...var_update_scheduler, [name]: value });
  };

  const onSubmit_updated_data = async (e) => {
    e.preventDefault();
    if (errors["first_name"]) {
      errors["first_name"] = "Please add valid first name.";
    }else if (errors["last_name"]) {
      errors["last_name"] = "Please add valid last name.";
    } 
    else if (errors["contact_number"]) {
      errors["contact_number"] = "Please add valid number.";
    } else if (errors["email"]) {
      errors["email"] = "Please add valid email.";
    } else {
      set_mdl_progress(true);
      const { first_name, last_name, address,email, contact_number, new_password,filteredArray } = var_update_scheduler;
      const associated_emails = filteredArray.map(item => item.text.match(/\(([^)]+)\)/)?.[1]).join(',');
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + user.token,
        },
        body: JSON.stringify({
          first_name,
          last_name,
          address,
          email,
          contact_number,
          new_password,
          associated_emails,
          user_id: user.user_id,
        }),
      };
      var apiUrl = updateUser + `${var_update_scheduler?.uid ? var_update_scheduler?.uid : user?.uuid}`;
      await fetch(apiUrl, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.code && res.code === 401) {
            addToast("Session expired. Please Login again.", {
              appearance: "error",
              autoDismiss: true,
            });
            //SessionExpire();
            localStorage.removeItem("user");
            setUser(null);
          } else {
            addToast(res.body.success, {
              appearance: "success",
              autoDismiss: true,
            });
          }
          // update_users_list(1,limit)
          // setPage(1);
          set_mdl_open(false);
          set_schedulerOrderlist(!schedulerOrderlist);
        })
        .catch((err) => {
          console.error("Error:", err);
        });
        set_mdl_progress(false);
    }
  };


    const onChangeScheduler = (e, { value }) => {
      const filteredArray = schedulers.filter(scheduler => value.includes(scheduler.value));
      set_update_scheduler(prevState => ({
        ...prevState,
        filteredArray
      }));
    }; 

  /* RENDER APP
	---------------------------------------------------------------------------------*/
  return (
    <>
      <Form method="POST" onSubmit={onSubmit_updated_data}>
        <div className="modal-header">
          <div className="modal-header-title">{user?.role === 'admin' ? 'Update Scheduler' : 'Profile'}</div>
          <div
            className="modal-header-close"
            onClick={() => set_mdl_open(false)}
          >
            <Icon name="times circle outline" />
          </div>
        </div>
        <div className="modal-content">
          <Form.Group>
            <Form.Field
              id="first_name"
              name="first_name"
              label="First Name"
              control={Input}
              required
              onChange={onChangeInput}
              value={var_update_scheduler?.first_name ? var_update_scheduler?.first_name : var_update_scheduler?.fName}
              error={errors["first_name"]}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              id="last_name"
              name="last_name"
              label="Last Name"
              control={Input}
              onChange={onChangeInput}
              value={var_update_scheduler?.last_name}
              error={errors["last_name"]}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              id="email"
              name="email"
              label="Email"
              control={Input}
              required
              onChange={onChangeInput}
              error={errors["email"]}
              value={var_update_scheduler?.email}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              id="password"
              name="new_password"
              required={false}
              label={user?.role === 'admin' ? 'New Password' : 'Password'}
              type="password"
              error={errors["new_password"]}
              control={Input}
              onChange={onChangeInput}
            />
          </Form.Group>
          <Form.Group>
            {schedulers?.length &&
            <div style={{width:"100%",padding:'0 9px'}}>
            <label style={{display:'block',width:"100%",color:"#4c4c4c",fontSize:'13px',fontWeight:'600',marginBottom:'6px'}}>Associate Emails</label>
            <Dropdown
              search
              selection
              style={{ width: "100%" }}
              className="treatment_options"
              id="treatment_options"
              name="treatment_options"
              placeholder="Select scheduler"
              label="Scheduler:"
              onChange={onChangeScheduler}
              value={var_update_scheduler?.filteredArray?.map(item => item.value) || []}
              options={schedulers}
              multiple
            />
            </div>
              }
            </Form.Group>
          {/* <Form.Group>
              <Form.Field
                id="address"
                name="address"
                label="Address"
                control={Input}
                required
                onChange={onChangeInput}
                value={var_update_scheduler.address}
              />
            </Form.Group> */}
        </div>

        <div className="modal-footer">
          <Button type="submit" color="red" disabled={var_disabled}>
          {user?.role === 'admin' ? 'Update Scheduler' : 'Save'}
          </Button>
        </div>
      </Form>
       {/***** MODAL: PROGRESS **************************************************************************/}
       <Modal
            id="mdl-progress"
            dimmer={"inverted"}
            open={var_mdl_progress}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            onClose={() => {}}
          >
            <MDL_PROGRESS
              set_mdl_open={set_mdl_progress}
              var_modaltitle="Please wait"
              var_message={"Stay on this page until this process finish."}
            ></MDL_PROGRESS>
          </Modal>

        {/***** END MODAL: PROGRESS **************************************************************************/}
        
    </>
  );
}
