import moment from "moment";
import React, { useContext, useEffect, useState} from "react";
import {
  Button,
  Input,
  Checkbox,
  Form,
  Grid,
  Modal,
  Search,
  Segment,
  Table,
  Tab,
  TabPane,
} from "semantic-ui-react";
import MDL_PROGRESS from "../../../components/cmp-progress/cmp-progress";
import "./invalid-comments.css";
import {
  getInvalidCommentsAPI,
  missingUnitsAPI,
  syncMissingOrder,
  getMissingInvoices,
  resyncInvalidCommentsAPI,
} from "../../../constants";
import AppPagination from "../../../components/cmp-pagination/Pagination";
import { AppContext } from "../../../Context/AppContext";
import { useToasts } from "react-toast-notifications";

export default function InvalidCommentsMissingUnits() {
  const { userInfo } = useContext(AppContext);
  const [invalid_comments, setInvalidComments] = useState([]);
  const [page, setPage] = useState(1);
  const [user] = userInfo;
  const [filter, setFilter] = useState("invalid_comments");
  const [limit, setLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [selected_invoices, setSelectedInvoices] = useState([]);
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const [var_change_pane,set_change_pane] = useState(0);
  const [missingOrder, setMissingOrder] = useState([]);
  const[TotalMissingOrders,setTotalMissingOrders]=useState(0);
  const { addToast } = useToasts();
  const [missingOrderNumber, setMissingOrderNumber] = useState("");
  const [pageInvocie, setPageInvocie] = useState(1);
  const [limitInvocie, setLimitInvocie] = useState(10);

  useEffect(() => {
    if(searchQuery === ''){
      getInvalidComments(page, limit, searchQuery);
    }
  }, [page, limit, filter,searchQuery]);

  useEffect(() => {
    if(var_change_pane === 0){
      setPage(1);
      getInvalidComments(1,limit,searchQuery);
    }
  }, [var_change_pane]);

  useEffect(() => {
    if(var_change_pane === 1){
      setPageInvocie(1);
      getMissingOrderInvocies(1,limit,searchQuery);
    }
  }, [var_change_pane]);

  useEffect(() => {
    if(searchQuery === ''){
      getMissingOrderInvocies(pageInvocie,limitInvocie,searchQuery);
    }
  }, [pageInvocie, limitInvocie, filter,searchQuery]);


  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if(searchQuery > 0){
        getInvalidComments(1, limit, searchQuery);
      }
    }, 1200);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  /**
   *
   * @param {*} page_no will be the specific page on which you want to go
   * @param {*} limit_range how much records you need in a call
   * @param {*} q if there is any search text
   */
  async function getInvalidComments(
    page_no = page,
    limit_range = limit,
    q = null
  ) {
    let query_params = `?page=${page_no ? page_no : page}&limit=${
      limit_range
        ? limit_range === "all"
          ? 99999
          : limit_range
        : limit === "all"
        ? 99999
        : limit
    }`;

    // Add search query parameter if provided
    if (q) {
      query_params = `?filter_by=${filter === 'all' ? '' : filter}&search=${q}&page=${
        page_no ? page_no : page
      }&limit=${limit_range ? limit_range : limit}`;
    } else {
      query_params = `?filter_by=${filter === 'all' ? '' : filter}&page=${
        page_no ? page_no : page
      }&limit=${limit_range ? limit_range : limit}`;
    }

    set_mdl_progress(true);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + user.token,
      },
    };
    await fetch(getInvalidCommentsAPI + query_params, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.statusCode === 200) {
          var buildingFetch = [];
          const { data } = res.body;
          if (data) {
            buildingFetch = data;
          } else {
            buildingFetch = [];
          }

          if ((page && page > 1) || (page_no && page_no > 1))
            buildingFetch = q
              ? [...buildingFetch]
              : [...invalid_comments, ...buildingFetch];

          setInvalidComments(buildingFetch);
          // setInvalidComments([
          //   {
          //     invoice_id: 247285,
          //     uuid: "4b2c041b-f63f-4d17-836e-56a5156fa762",
          //     work_date: "2024-03-26T11:19:57.000Z",
          //     order_number: 292778,
          //     order_id: 276689,
          //     building_name: "230 Brittany Drive",
          //     invalidUnitsComments: [
          //       {
          //         unit: "1007",
          //         uuid: "550663",
          //         comment: "Cancelled prior to my arrival.\nNOT COMPLETED.",
          //         prep_level: null,
          //         activity_level: null,
          //         sanitation_level: null,
          //       },
          //       {
          //         unit: "1003",
          //         uuid: "550662",
          //         comment:
          //           "Cockroach Inspection.\nNo preparation required. Service completed.\nInspected cracks and crevices in kitchen and bathroom, behind fridge/stove. Inspected insect monitors. \nNo live roaming CR activity seen.\nNo CR activity noted on monitors during inspection.\nNo follow up required.",
          //         prep_level: "Prepared",
          //         activity_level: null,
          //         sanitation_level: null,
          //       },
          //       {
          //         unit: "604",
          //         uuid: "550659",
          //         comment:
          //           "Cockroach Residual Treatment. \nFully not prepared for treatment. NOT COMPLETED. Cupboards not emptied. \nReschedule required.",
          //         prep_level: "Not prepared",
          //         activity_level: null,
          //         sanitation_level: null,
          //       },
          //     ],
          //   },
          //   {
          //     invoice_id: 247284,
          //     uuid: "66cc067a-b15d-401a-ad9d-d1297eb59769",
          //     work_date: "2024-03-26T11:01:14.000Z",
          //     order_number: 292439,
          //     order_id: 276350,
          //     building_name: "101 Boulevard Sacre Coeur",
          //     invalidUnitsComments: [
          //       {
          //         unit: "3",
          //         uuid: "549919",
          //         comment:
          //           "Cockroach Inspection.\nNo preparation required. Service completed.\nInspected cracks and crevices in kitchen and bathroom, behind fridge/stove. Inspected insect monitors. \nNo live roaming CR activity seen.\n1 live CR activity noted on monitors during inspection.\nFollow up CR Residual Treatment recommended. (1 week)",
          //         prep_level: "Prepared",
          //         activity_level: null,
          //         sanitation_level: null,
          //       },
          //       {
          //         unit: "712",
          //         uuid: "549928",
          //         comment: null,
          //         prep_level: null,
          //         activity_level: null,
          //         sanitation_level: null,
          //       },
          //       {
          //         unit: "715",
          //         uuid: "549930",
          //         comment:
          //           "Cockroach Inspection.\nNo preparation required. Service completed.\nNo pre-existing monitoring traps found.\nFollow up CR Inspection recommended. (In 1 week)",
          //         prep_level: "Prepared",
          //         activity_level: null,
          //         sanitation_level: null,
          //       },
          //     ],
          //   },
          //   {
          //     invoice_id: 246788,
          //     uuid: "33fa9f8f-92cc-4f53-8a77-bc50654c8a51",
          //     work_date: "2024-03-26T08:58:00.000Z",
          //     order_number: 291071,
          //     order_id: 274982,
          //     building_name: "7 Bayswater Place",
          //     invalidUnitsComments: [
          //       {
          //         unit: "203",
          //         uuid: "546985",
          //         comment: null,
          //         prep_level: null,
          //         activity_level: null,
          //         sanitation_level: null,
          //       },
          //     ],
          //   },
          //   {
          //     invoice_id: 246789,
          //     uuid: "9e52b6f4-0d61-407f-ae6a-98571600ac4d",
          //     work_date: "2024-03-26T09:19:00.000Z",
          //     order_number: 290117,
          //     order_id: 274028,
          //     building_name: "31 Bayswater Place",
          //     invalidUnitsComments: [
          //       {
          //         unit: "216",
          //         uuid: "544934",
          //         comment: null,
          //         prep_level: null,
          //         activity_level: null,
          //         sanitation_level: null,
          //       },
          //       {
          //         unit: "307",
          //         uuid: "544935",
          //         comment: null,
          //         prep_level: null,
          //         activity_level: null,
          //         sanitation_level: null,
          //       },
          //       {
          //         unit: "311",
          //         uuid: "544936",
          //         comment: null,
          //         prep_level: null,
          //         activity_level: null,
          //         sanitation_level: null,
          //       },
          //       {
          //         unit: "316",
          //         uuid: "544937",
          //         comment: null,
          //         prep_level: null,
          //         activity_level: null,
          //         sanitation_level: null,
          //       },
          //       {
          //         unit: "317",
          //         uuid: "544938",
          //         comment: null,
          //         prep_level: null,
          //         activity_level: null,
          //         sanitation_level: null,
          //       },
          //       {
          //         unit: "215",
          //         uuid: "544933",
          //         comment: null,
          //         prep_level: null,
          //         activity_level: null,
          //         sanitation_level: null,
          //       },
          //       {
          //         unit: "121",
          //         uuid: "549832",
          //         comment: null,
          //         prep_level: null,
          //         activity_level: null,
          //         sanitation_level: null,
          //       },
          //       {
          //         unit: "202",
          //         uuid: "549833",
          //         comment: null,
          //         prep_level: null,
          //         activity_level: null,
          //         sanitation_level: null,
          //       },
          //       {
          //         unit: "217",
          //         uuid: "549834",
          //         comment: null,
          //         prep_level: null,
          //         activity_level: null,
          //         sanitation_level: null,
          //       },
          //       {
          //         unit: "210",
          //         uuid: "544932",
          //         comment: null,
          //         prep_level: null,
          //         activity_level: null,
          //         sanitation_level: null,
          //       },
          //       {
          //         unit: "116",
          //         uuid: "544931",
          //         comment: null,
          //         prep_level: null,
          //         activity_level: null,
          //         sanitation_level: null,
          //       },
          //       {
          //         unit: "115",
          //         uuid: "544930",
          //         comment: null,
          //         prep_level: null,
          //         activity_level: null,
          //         sanitation_level: null,
          //       },
          //     ],
          //   },
          //   {
          //     invoice_id: 246791,
          //     uuid: "0be4fd26-766b-4abc-87d2-38d0eb3c61eb",
          //     work_date: "2024-03-26T13:37:00.000Z",
          //     order_number: 289091,
          //     order_id: 273002,
          //     building_name: "52 Bayswater Place",
          //     invalidUnitsComments: [
          //       {
          //         unit: "419",
          //         uuid: "542486",
          //         comment: null,
          //         prep_level: null,
          //         activity_level: null,
          //         sanitation_level: null,
          //       },
          //     ],
          //   },
          // ]);
        }
        set_mdl_progress(false);
      })
      .catch((error) => {
        set_mdl_progress(false);
        console.error("Error building get:", error);
      });
  }

  /**
   * API call for missing order numbers
   */

  async function getMissingOrderInvocies( page_no = pageInvocie,
    limit_range = limitInvocie,
    q = null) {
    console.log("getMissingOrderNumbers:");
    set_mdl_progress(true);
    await fetch(getMissingInvoices+`?page=${page_no?page_no: pageInvocie}&limit=${limit_range?limit_range: limitInvocie}`)
      .then((response) => response.json())
      .then((res) => {
        set_mdl_progress(false);
        var orderFetch = [];
        if (res.statusCode == 200) {
          setTotalMissingOrders(res.body.total_order);
          orderFetch = res.body.data;
              if (
                res.body.page_number > 1 &&
                res &&
                res &&
                res?.body.length === 0
              ) {
                setPage(res.body.page_number - 1);
              }
             
            } else {
              orderFetch = [];
            }
            if ((page_no && page_no > 1)){
              orderFetch = [...missingOrder, ...orderFetch];
            }else{
              setMissingOrder([]);
            }
            setMissingOrder(orderFetch);
            set_mdl_progress(false);
        })
      .catch((error) => {
        set_mdl_progress(false);
        console.error("Error:", error);
      });
  }

  /**
   * API call for resync after selection of units
   */
  async function asyncAPICall() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + user.token,
      },
      body: JSON.stringify({ invoiceIds: selected_invoices }),
    };
    set_mdl_progress(true);
    await fetch(resyncInvalidCommentsAPI, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        set_mdl_progress(false);
        if (res.code == 200) {
          setSelectedInvoices([]);
          addToast(res.success, {
            appearance: "success",
            autoDismiss: true,
          });
        } else {
          addToast(
            res.error ||
              res.message ||
              res.error?.message ||
              "There is some server side issue. Please try again later.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        }
      })
      .catch((error) => {
        console.log("Error::::", error);
        set_mdl_progress(false);
      });
  }

  /**
   * API call for resync after selection of units
   */
  async function missingUnitsAPICall() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + user.token,
      },
    };
    set_mdl_progress(true);
    await fetch(missingUnitsAPI, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        set_mdl_progress(false);
        if (res.code == 200) {
          addToast(res.success, {
            appearance: "success",
            autoDismiss: true,
          });
        } else {
          addToast(
            res.error ||
              res.message ||
              res.error?.message ||
              "There is some server side issue. Please try again later.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        }
      })
      .catch((error) => {
        console.log("Error::::", error);
        set_mdl_progress(false);
      });
  }
  async function downloadReport(page_no = page, limit_range = limit, q = null) {
    let query_params = `?filter_by=${filter}&page=${
      page_no ? page_no : page
    }&limit=${
      limit_range
        ? limit_range === "all"
          ? 99999
          : limit_range
        : limit === "all"
        ? 99999
        : limit
    }`;

    // Add search query parameter if provided
    if (q) {
      query_params += `&search=${q}`;
    }

    const url = `${getInvalidCommentsAPI}${query_params}&download=true`;
    try {
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  }


  /*
  Download Invoice Report
  */
  async function downloadInvoiceReport(page_no = pageInvocie, limit_range = limitInvocie, q = null) {
    let query_params = `?filter_by=${filter}&page=${
      page_no ? page_no : page
    }&limit=${
      limit_range
        ? limit_range === "all"
          ? 99999
          : limit_range
        : limitInvocie === "all"
        ? 99999
        : limitInvocie
    }`;

    // Add search query parameter if provided
    if (q) {
      query_params += `&search=${q}`;
    }

    const url = `${getMissingInvoices}${query_params}&download=true`;
    try {
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  }


  /**
   * This will render the table and accordion of invalid comments
   * @param {*} val data of row
   * @param {*} index index of each row
   * @returns
   */
  function renderTableItems(val, index) {
    const itemRows = [
      <Table.Row key={index}>
        <Table.Cell>
          <Checkbox
            checked={
              selected_invoices.filter((v) => v === val.invoice_id).length > 0
            }
            onChange={(e, data) => {
              if (data.checked) {
                let data = [...selected_invoices];
                data.push(val.invoice_id);
                setSelectedInvoices(data);
              } else {
                console.log("Data::", selected_invoices);
                const updatedItems = selected_invoices.filter(
                  (item, index) => item !== val.invoice_id
                );
                setSelectedInvoices(updatedItems);
              }
            }}
          />
        </Table.Cell>
        <Table.Cell>{val.invoice_id}</Table.Cell>
        <Table.Cell>{val.building_name}</Table.Cell>
        <Table.Cell>{val.order_number}</Table.Cell>
        <Table.Cell>{moment(val.work_date).format("lll")}</Table.Cell>
      </Table.Row>,
    ];

    itemRows.push(
      <Table.Row key={"row-expand-" + index}>
        <Table.Cell colSpan="6">
          <Segment padded>
            <Grid columns={5}>
              <Grid.Column width={1} className="expand-header">
                <span>Unit</span>
              </Grid.Column>

              <Grid.Column width={3} className="expand-header">
                <span>Prep Level</span>
              </Grid.Column>

              <Grid.Column width={3} className="expand-header">
                <span>Activity Level</span>
              </Grid.Column>

              <Grid.Column width={3} className="expand-header">
                <span>Sanitation Level</span>
              </Grid.Column>

              <Grid.Column width={6} className="expand-header">
                <span>Comment</span>
              </Grid.Column>
            </Grid>
            {val.invalidUnitsComments.map((units, ind) => (
              <Grid columns={5}>
                <Grid.Column width={1}>
                  <text>{units.unit}</text>
                </Grid.Column>
                <Grid.Column width={3}>
                  <text>{units.prep_level || "N/A"}</text>
                </Grid.Column>
                <Grid.Column width={3}>
                  <text>{units.activity_level || "N/A"}</text>
                </Grid.Column>
                <Grid.Column width={3}>
                  <text>{units.sanitation_level || "N/A"}</text>
                </Grid.Column>
                <Grid.Column width={6}>
                  <text>{units.comment || "No Comment"}</text>
                </Grid.Column>
              </Grid>
            ))}
          </Segment>
        </Table.Cell>
      </Table.Row>
    );

    return itemRows;
  }

  /**
   * We will get the text from the search and send it in our APi call
   * @param {*} event on search text change event
   */
  function doSearch(event) {
    const value = event.target.value.toString().toLowerCase();
    setSearchQuery(value);
  }

  function onChangeFilter(event, { value }) {
    setFilter(value);
    // getBuildingUnits();
    //getBuildingOrders(1,limit,value)
  }


  const handleSyncMissingOrder = () => {
    if (!missingOrderNumber.trim()) {
      //alert("Please enter a missing order number.");
      addToast(
        "Please enter a missing order number.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
      return;
    }
    // Call API or update state accordingly
    syncMissingOrderNumber(missingOrderNumber);
    
  };

  async function syncMissingOrderNumber(missingOrderNumber) {
    set_mdl_progress(true);
    const url = `${syncMissingOrder}${missingOrderNumber}`;
      const response = await fetch(url)
      .then((response) => response.json())
      .then((res) => {
        set_mdl_progress(false);
        if (res.statusCode == 200) {
          addToast(res.body.success, {
            appearance: "success",
            autoDismiss: true,
          });
          setMissingOrderNumber(""); // Clear input after sync
        }
        else if (res.statusCode == 204) {
          addToast(res.body.success, {
            appearance: "info",
            autoDismiss: true,
          });
          setMissingOrderNumber(""); // Clear input after sync
        } else {
          addToast(
            res.error ||
              res.message ||
              res.error?.message ||
              "There is some server side issue. Please try again later.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        }
      })
      .catch((error) => {
        console.log("Error::::", error);
        set_mdl_progress(false);
      });
  }

  const panes = [
    { menuItem: { key: 'InvalidComments', icon: 'table', content: 'Invalid Comments' }, render: () => <><TabPane><div className="app-building-container">
    <div className="app-content-action">
      {/* Action button against this page */}
      <div className="app-content-action-right">
        <Form.Select
          className="sortby_optionss"
          id="sortby_optionss"
          name="sortby_optionss"
          placeholder="Sort By"
          label="Filter: "
          onChange={onChangeFilter}
          value={filter}
          style={{marginRight:'8px'}}
          options={[
            {
              key: 0,
              text: "Invalid Comments",
              value: "invalid_comments",
            },
            { key: 3, text: "All", value: "all" },
          ]}
        />
        <Button color="orange" onClick={missingUnitsAPICall}>
          <img src="/missing-units.svg" alt="icon" />
          Missing Units
        </Button>
        <Button
          disabled={selected_invoices.length === 0}
          color="green"
          onClick={() => asyncAPICall()}
        >
          <img src="/resync.svg" alt="sync" />
          Resync
        </Button>

        <Button
          color="blue"
          onClick={() => {
            downloadReport(1, limit, searchQuery);
          }}
          disabled={!invalid_comments.length}
          content="Download"
        >
          <img src="/history.png" alt="icon" />
          Export
        </Button>
      </div>
    </div>

    <div className="app-card">
      {/* Card title and search div */}
      <div className="app-card-header building-managment-header-card">
        {selected_invoices.length > 0 && (
          <Button
            className="btn-uncheck"
            size="mini"
            onClick={() => {
              setSelectedInvoices([]);
            }}
          >
            X
          </Button>
        )}
        <div className="app-card-title">
          {selected_invoices.length > 0
            ? `Selected (${selected_invoices.length})`
            : `${filter === "all" ? "All" : "Invalid"} Comments (${
                invalid_comments.length
              })`}
        </div>
        <div className="app-content-search">
          <Search
            placeholder="Search here (Invoice id, order number or building name)"
            onSearchChange={(value) => doSearch(value)}
            showNoResults={false}
            value={searchQuery}
          />
        </div>
      </div>

      {/* Main table section */}
      <div className="app-table-wrapper">
        <Table unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell>Invoice ID</Table.HeaderCell>
              <Table.HeaderCell>Building</Table.HeaderCell>
              <Table.HeaderCell>Order #</Table.HeaderCell>
              <Table.HeaderCell>Work Date</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!var_mdl_progress && invalid_comments.map((val, index) =>
              renderTableItems(val, index)
            )}
          </Table.Body>
        </Table>
        {!var_mdl_progress && invalid_comments.length === 0 && (
          <div className="nodata-placeholder">
            There is no invalid comments
          </div>
        )}
      </div>
    </div>
    <AppPagination
      limit={limit}
      setLimit={setLimit}
      page={page}
      setPage={setPage}
      rowsTotal={invalid_comments.length}
      showAll={true}
    />
  </div> </TabPane></> },
    { menuItem: {key:'MissingOrders',icon:'list alternate outline', content:'Missing Order List'}, render: () => <TabPane> <div className="app-scheduler-container">

    <div className="app-content-action">
        <div className="app-content-action-right">
              <Input
              type="text"
              placeholder="Enter Missing Order Number"
              value={missingOrderNumber}
              style={{ width:"270px", marginRight: "2px" }}
              onChange={(e) => setMissingOrderNumber(e.target.value)}
            />
            <Button color="red" onClick={handleSyncMissingOrder}>
              <img src="/plus-icon.svg" alt="icon" /> Sync Missing Order
            </Button>
            <Button
            color="blue"
            onClick={() => {
              downloadInvoiceReport();
            }}
            disabled={!missingOrder.length}
            content="Download"
            >
            <img src="/history.png" alt="icon" />
            Export
            </Button>
        </div>
    </div>

    <div className="app-card">
        <div className="app-card-header">
            <div className="app-card-title">Missing Invoice List ({missingOrder.length})</div>
        </div>

        <div className="app-table-wrapper">
            <Table unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Building</Table.HeaderCell>
                        <Table.HeaderCell>Order #</Table.HeaderCell>
                        <Table.HeaderCell>Work Date</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                {!var_mdl_progress && missingOrder.map((order, i) =>
                    <Table.Row key={i}>
                        <Table.Cell>{order.buildingName}</Table.Cell>
                        <Table.Cell>{order.orderNumber}</Table.Cell>
                        <Table.Cell>{moment(order.workDate).format("MM-DD-YYYY")}</Table.Cell>
                        <Table.Cell>{order.orderStatus}</Table.Cell>
                    </Table.Row>
                )}
                </Table.Body>
            </Table>
            {!var_mdl_progress && missingOrder.length === 0 &&
                <div className="nodata-placeholder">
                    There is no missing order list to display
                </div>
            }
        </div>
    </div>
    {missingOrder.length !== TotalMissingOrders && 
        <AppPagination limit={limitInvocie} setLimit={setLimitInvocie} page={pageInvocie} setPage={setPageInvocie} rowsTotal={missingOrder.length}/>
    }
  </div></TabPane> },   
  ]

  const handleTabChange = (e, { activeIndex }) => {
    set_change_pane(activeIndex); // Update active tab index
  };


  return (
    <>
      <div className="app-content">
          <Tab panes={panes} activeIndex={var_change_pane} onTabChange={handleTabChange}/>
        
      
      {/***** MODAL: PROGRESS **************************************************************************/}
      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress}
          var_modaltitle="Please wait"
          var_message={"Stay on this page until this process finish."}
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}
      </div>
    </>
  );
}
