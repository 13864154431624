import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import './view-scheduler.css'

const ViewScheduler = () => {

    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    const history = useHistory();
    const [ data, setData ] = useState();



    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
	useEffect(() => {
        if(history.location.state){
            setData(history.location.state.data)
            
        }else{
            history.goBack();
        }
	},[])



    /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/


    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/



    /* RENDER APP
	---------------------------------------------------------------------------------*/
    return (
        <>
            <div className="app-content">
                <div className="app-view-scheduler">
                    <div className="app-backto-prev-page">
                        <div className="app-goback-button" onClick={history.goBack}><Icon name="arrow left" /> Go Back</div>
                    </div>
                    <div className="app-card">
                        <div className="app-card-header">
                            <div className="app-card-title">Scheduler Management</div>
                        </div>
                        <div className="view-scheduler-section">
                            <div className="view-scheduler-row">
                                <div className="view-scheduler-label">Scheduler:</div>
                                <div className="view-scheduler-field">{data && data.first_name} {data && data.last_name}</div>
                            </div>
                            <div className="view-scheduler-row">
                                <div className="view-scheduler-label">Email:</div>
                                <div className="view-scheduler-field">{data && data.email}</div>
                            </div>
                            <div className="view-scheduler-row">
                                <div className="view-scheduler-label">Buildings:</div>
                                <div className="view-scheduler-field">{data && data.scheduler_building_count}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewScheduler
