import React, { createContext, useEffect, useState } from "react";
import {
  getSingleBuilding,
} from "../constants";

export const AppContext = createContext();

export const ContextProvider = (props) => {
  const [var_users, set_users] = useState([]);
  const [user, setUser] = useState();
  const [building_info, setBuildingInfo] = useState();
  const [building_units, setBuildingUnits] = useState([]);
  const [single_building_data, setSingleBuildingData] = useState([]);
  const [form_validation_error, setFormError] = useState([{}]);
  const [cart_items, setCartItems] = useState(null);
  const [selectedNavBarPosition, setSelectedNavBarPosition] = useState(0);
  const [open, setOpen] = useState(false); //Update pin modal state
  const [openAlertModel, setOpenAlertModel] = useState(false); //Alert modal state
  const [searchedData, setSearchedDate] = useState();
  const [var_add_unit, set_add_unit] = useState([{ unit_number: "", followup: false, key: 0 }]);
  const [ordersCount, setOrderCount] = useState();
  const [building_order, setBuildingOrder] = useState({});
  const [selected_building, setSelectedBuilding] = useState();
  const [dynamicUnitData, setDynamicUnitData] = useState([]);
  const [checkedUnits, setCheckedUnits] = useState({});
  const [UncheckedUnits, setUnCheckedUnits] = useState("");
  const [var_po_mo_value, set_po_mo_value] = useState("");
  const [sideSliderCart, setSideSliderCart] = useState(false);
  const [orderSummary,setorderSummary] = useState([]);
  const [var_ShowHeaderSearch, setShowHeaderSearch] = useState(false);
  const [buildingUpdate, set_BuildingUpdate] = useState(false);
  const [var_default_pane,set_default_pane] = useState(0);
  const [var_order_filter,set_order_filter] = useState(3);
  const [schedulerOrderlist, set_schedulerOrderlist] = useState(false);

  const [pestTreated, setPestTreated] = useState([
    {
      key: "Ants",
      text: "Ants",
      value: "ae633791-f0e1-488c-819c-31f853881f34",
    },
    {
      key: "Pharaoh Ants",
      text: "Pharaoh Ants",
      value: "99c03c9b-3ae2-4562-af9a-b8c021041e34",
    },
    {
      key: "Cockroaches",
      text: "Cockroaches",
      value: "5ce985d4-308e-4313-975b-dfcfa63a0ab6",
    },
  ]);

  const [treatmentOption, setTreatmentOption] = useState([
    {
      key: "Inspection",
      text: "Inspection",
      value: "d663a09a-71cc-4d4f-bcb7-f1a7216ef600",
    },
    {
      key: "Gel and Dust",
      text: "Gel and Dust",
      value: "8499339b-7f42-4407-829f-ca0660d23061",
    },
    {
      key: "Flush and Vac Spray",
      text: "Flush and Vac Spray",
      value: "ea2c7ce9-90f2-4c70-b401-dec1c3dea6d8",
    },
  ]);

  const [orders, setOrders] = useState([
    {
      cell_index: 1,
      scheduler_name: "Usman Ahsan",
      building: "20 Paisley Blvd",
      schedule_date: "16 April,2021",
      status: "Scheduled",
    },
    {
      cell_index: 2,
      scheduler_name: "Zaid Javeed",
      building: "1023 Pembridge Crescent",
      schedule_date: "N/A",
      status: "Pending",
    },
    {
      cell_index: 3,
      scheduler_name: "Ali",
      building: "2980 Don Mills Rd",
      schedule_date: "N/A",
      status: "Pending",
    },
    {
      cell_index: 4,
      scheduler_name: "Ahmad",
      building: "890 Mount Pleasant",
      schedule_date: "10 April,2021",
      status: "Complete",
    },
    {
      cell_index: 5,
      scheduler_name: "Rahat",
      building: "746 Fanshawe Park Rd",
      schedule_date: "09 April,2021",
      status: "Complete",
    },
  ]);
  const [requests, setRequests] = useState([
    {
      order_no: "123",
      customer_name: "Zaid Javeed",
      building: "3330 Weston Rd",
      time_date: "5/4/2021 10:30 PM",
      status: "Pending",
    },
    {
      order_no: "456",
      customer_name: "Ahmed Shuja",
      building: "3201 Lawrence Ave E",
      time_date: "5/4/2021 08:30 PM",
      status: "Scheduled",
    },
    {
      order_no: "789",
      customer_name: "Abdul Razaq",
      building: "20 Paisley Blvd",
      time_date: "5/1/2021 12:30 PM",
      status: "Completed",
    },
  ]);
  const [requestTableHeading, setRequestTableHeading] = useState([
    {
      label: "Order#",
    },
    {
      label: "Customer Name",
    },
    {
      label: "Building",
    },
    {
      label: "Time & Date",
    },
    {
      label: "Status",
    },
    {
      label: "Action",
    },
  ]);
  const [statistics, setStatistics] = useState({
    customer: 200,
    scheduler: 190,
    requests: 300,
  });

  const [buildings, setBuildings] = useState([
    {
      key: "20 Paisley Blvd",
      text: "20 Paisley Blvd",
      value: "20 Paisley Blvd",
      no_of_beds: 4,
      wo_mo: false,
    },
    {
      key: "21 Welsford Garden",
      text: "21 Welsford Garden",
      value: "21 Welsford Garden",
      no_of_beds: 3,
      wo_mo: false,
    },
    {
      key: "25 Duncanwoods",
      text: "25 Duncanwoods",
      value: "25 Duncanwoods",
      no_of_beds: 5,
      wo_mo: true,
    },
    {
      key: "52 Park St E",
      text: "52 Park St E",
      value: "52 Park St E",
      no_of_beds: 7,
      wo_mo: false,
    },
  ]);

  const [users, setUsers] = useState([]);
  const [schedulers, setSchedulers] = useState([]);
  const validateTextField = (value) => {
    const regex = /^[A-Za-z\s]+$/;
    return regex.test(value);
};
  const [userTableHeading, setUSerTableHeading] = useState([
    {
      label: "Id",
    },
    {
      label: "Customer Name",
    },
    {
      label: "Address",
    },
    {
      label: "Email",
    },
    {
      label: "Contact #",
    },
    {
      label: "Action",
    },
  ]);
  const [buildingTableHeading, setBuildingTableHeading] = useState([
    {
      label: "Building Name",
    },
    {
      label: "Address",
    },
    {
      label: "City",
    },
    {
      label: "Scheduler",
    },
    {
      label: "Customer",
    },
    {
      label: "Actions",
    },
  ]);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const { role } = JSON.parse(localStorage.getItem("user"));
      if (role === "customer") {
        setBuildingInfo(JSON.parse(localStorage.getItem("building")));
      }
      setUser(JSON.parse(localStorage.getItem("user")));
    } else {
      setUser(null);
    }
  }, []);
  useEffect(() => {
    if (selected_building && window?.location?.pathname?.includes("/automatic-followup-order-request/")) {

      getBuildingUnits(selected_building?.value);
      

    }
  }, [selected_building]);
  
  /**
   * get building units on basis of selected building id
   * @param {*} selected_id 
   */
  async function getBuildingUnits(selected_id) {
     
    await fetch(
      `${getSingleBuilding}${selected_id || selected_building?.value}`
    )
      .then((response) => response.json())
      .then((response) => {
        console.log("response::building::", response);    
        const final_data = response.data[0];
        setSingleBuildingData(final_data);
        setBuildingUnits(final_data?.units ? final_data?.units : []);
      });
  }

  return (
    <AppContext.Provider
      value={{
        users: [var_users, set_users],
        userInfo: [user, setUser],
        cartItems: [cart_items, setCartItems],
        orderCunt: [ordersCount, setOrderCount],
        building: [buildings, setBuildings],
        sbuildingData : [single_building_data, setSingleBuildingData],
        bInfo: [building_info, setBuildingInfo],
        ordersList: [orders, setOrders],
        search: [searchedData, setSearchedDate],
        pestDetails: [pestTreated, setPestTreated],
        treatmentOptions: [treatmentOption, setTreatmentOption],
        updatePinModalOpen: [open, setOpen],
        multipleUnits: [dynamicUnitData, setDynamicUnitData],
        selected_units: [var_add_unit, set_add_unit],
        building_order_details: [building_order, setBuildingOrder],
        error_validation: [form_validation_error, setFormError],
        selectedBuilding: [selected_building, setSelectedBuilding],
        buildingUnits: [building_units, setBuildingUnits],
        checkedUnitsData: [checkedUnits, setCheckedUnits],
        UncheckedUnitsData: [UncheckedUnits, setUnCheckedUnits],
        defaultPane:[var_default_pane,set_default_pane],
        orderFilter:[var_order_filter,set_order_filter],
        //Only for scheduler
        orderRequest: [requests, setRequests],
        rTableHeadings: [requestTableHeading, setRequestTableHeading],
        updatePassword: [open, setOpen],
        sliderCart: [sideSliderCart, setSideSliderCart],
        po_mo_value:[var_po_mo_value, set_po_mo_value],
        schedulerCheckOrderlist:[schedulerOrderlist, set_schedulerOrderlist],
        //End scheduler

        //Only for admin
        useStatistics: [statistics, setStatistics],
        uTableHEadings: [userTableHeading, setUSerTableHeading],
        usersData: [users, setUsers],
        schedulersData: [schedulers, setSchedulers],
        buildingTableHeader: [buildingTableHeading, setBuildingTableHeading],
        updatePasswordModalOpen: [open, setOpen],
        navBarCurrentIndex: [selectedNavBarPosition, setSelectedNavBarPosition],
        openAlert: [openAlertModel, setOpenAlertModel],
        orderPriceSummary: [orderSummary,setorderSummary],
        BuildingUpdate: [buildingUpdate,set_BuildingUpdate],
        ShowHeaderSearch:[var_ShowHeaderSearch, setShowHeaderSearch],
        validateTextField,
        //End admin
      }}
    >
      {props.children}
    </AppContext.Provider>
    
  );
};
